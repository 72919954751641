<template>
  <v-container fluid>
    <v-card class="settings-card">
      <v-row>
        <v-col
          cols="12"
          class="text-left mb-10"
        >
          <h1 class="mb-4 title-style">
            Account Settings ⚙️
          </h1>
          <span class="line" />
          <v-row class="mt-3 line pb-10">
            <v-col cols="3">
              <h2 class="settings-subtitle">
                Advanced
              </h2>
            </v-col>
            <v-col cols="5">
              <div class="d-flex align-center justify-space-between">
                <span><b>Location Verification</b> <br> Enable or disable the location verification in App Check-ins.</span> <span class="d-flex align-center"><v-switch
                  v-model="settings.locationVerification"
                  inset
                  class="ml-3"
                  @change="updateSettings"
                /> Yes</span>
              </div>
              <div class="d-flex align-center justify-space-between">
                <span><b>Document verification</b> <br> Enable or disable Document verification step.</span><span class="d-flex align-center"><v-switch
                  v-model="settings.documentVerification"
                  inset
                  class="ml-3"
                  @change="updateSettings"
                />Yes</span>
              </div>
              <div class="d-flex align-center justify-space-between">
                <span><b>Questionnaire</b> <br>Enable or disable Questionnaire in Check-ins process.</span><span class="d-flex align-center"><v-switch
                  v-model="settings.questionnaireVerification"
                  inset
                  class="ml-3"
                  @change="updateSettings"
                />Yes</span>
              </div>
              <div class="d-flex align-center justify-space-between">
                <span><b>Staff checkin on budget</b> <br> Enable or disable staff checkin on budget.</span><span class="d-flex align-center"><v-switch
                  v-model="settings.staffCheckinBudget"
                  inset
                  class="ml-3"
                  @change="updateSettings"
                />Yes</span>
              </div>
              <div class="d-flex align-center justify-space-between">
                <span><b>Staff checkin on issue</b><br>Enable or disable staff checkin on issue.</span><span class="d-flex align-center"><v-switch
                  v-model="settings.staffCheckinIssue"
                  inset
                  class="ml-3"
                  @change="updateSettings"
                />Yes</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
    name: 'Settings',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        settings: {
          locationVerification: false,
          documentVerification: false,
          questionnaireVerification: false,
          staffCheckinBudget: false,
          staffCheckinIssue: false,
        },
        loading: false,
      };
    },
    computed: {
      settingsList () {
        return this.$store.getters['settings/getSettings'];
      },
    },
   async mounted () {
    this.fetchSettings();
    },
    methods: {
     async setSettingsValues () {
        this.loading = true;
        this.settings.locationVerification = this.settingsList.settings.location_verification;
        this.settings.documentVerification = this.settingsList.settings.document_verification;
        this.settings.questionnaireVerification = this.settingsList.settings.questionnaire_verification;
        this.settings.staffCheckinBudget = this.settingsList.settings.staff_checkin_budget;
        this.settings.staffCheckinIssue = this.settingsList.settings.staff_checkin_issue;
        this.loading = false;
      },
      async fetchSettings () {
        this.loading = true;
        await this.$store.dispatch('settings/fetchSettings').catch(() => {
        });
        this.setSettingsValues();
        this.loading = false;
      },
     async updateSettings () {
      this.loading = true;
      const settingsArray = {
        location_verification: this.settings.locationVerification,
        document_verification: this.settings.documentVerification,
        questionnaire_verification: this.settings.questionnaireVerification,
        staff_checkin_budget: this.settings.staffCheckinBudget,
        staff_checkin_issue: this.settings.staffCheckinIssue,
      };
        await this.$store.dispatch('settings/updateSettings', {
            settings: settingsArray,
          }).then(response => {
            this.loading = false;
            this.$store.dispatch('alert/onAlert', {
              message: 'Settings updated!.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
          }).catch(() => {
            this.loading = false;
          });
      },
    },
};

</script>
<style scoped>
.title-style {
  color: #37474F;
  font-size: 36px;
  font-weight: 600;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.settings-card{
  box-shadow: none !important;
  /* border: 1px solid #ccc; */
  padding: 0px !important;
}
.line {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.settings-subtitle {
  font-weight: 300;
}
</style>
